// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Json_decode from "../../../../node_modules/@glennsl/bs-json/src/Json_decode.bs.js";
import * as MultiselectInline from "../../packages/multiselect-inline/src/components/MultiselectInline.bs.js";

function reducer(state, action) {
  switch (action.TAG | 0) {
    case /* SelectItem */0 :
        return {
                selected: state.selected.concat([action._0]),
                searchInput: state.searchInput
              };
    case /* DeSelectItem */1 :
        var item = action._0;
        return {
                selected: state.selected.filter(function (i) {
                      return i.id !== item.id;
                    }),
                searchInput: state.searchInput
              };
    case /* UpdateSearchInput */2 :
        return {
                selected: state.selected,
                searchInput: action._0
              };
    
  }
}

function value(t) {
  return t.name;
}

var SelectableItem = {
  value: value,
  searchString: value
};

var Multiselect = MultiselectInline.Make(SelectableItem);

function unselectedItems(allItems, selected) {
  return allItems.filter(function (item) {
              return Belt_Option.isNone(Caml_option.undefined_to_opt(selected.find(function (selectedItem) {
                                  return selectedItem.id === item.id;
                                })));
            });
}

function SimpleMultiSelectInline(Props) {
  var placeholderOpt = Props.placeholder;
  var emptySelectionMessageOpt = Props.emptySelectionMessage;
  var allItemsSelectedMessageOpt = Props.allItemsSelectedMessage;
  var selected = Props.selected;
  var inputName = Props.inputName;
  var allItems = Props.allItems;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "Search";
  var emptySelectionMessage = emptySelectionMessageOpt !== undefined ? emptySelectionMessageOpt : "No items selected";
  var allItemsSelectedMessage = allItemsSelectedMessageOpt !== undefined ? allItemsSelectedMessageOpt : "All items selected";
  var match = React.useReducer(reducer, {
        selected: selected,
        searchInput: ""
      });
  var send = match[1];
  var state = match[0];
  return React.createElement("div", undefined, state.selected.map(function (item, index) {
                  return React.createElement("input", {
                              key: String(index),
                              name: inputName,
                              type: "hidden",
                              value: item.id
                            });
                }), React.createElement(Multiselect.make, {
                  placeholder: placeholder,
                  onChange: (function (value) {
                      Curry._1(send, {
                            TAG: /* UpdateSearchInput */2,
                            _0: value
                          });
                    }),
                  value: state.searchInput,
                  unselected: unselectedItems(allItems, state.selected),
                  selected: state.selected,
                  onSelect: (function (s) {
                      Curry._1(send, {
                            TAG: /* SelectItem */0,
                            _0: s
                          });
                    }),
                  onDeselect: (function (s) {
                      Curry._1(send, {
                            TAG: /* DeSelectItem */1,
                            _0: s
                          });
                    }),
                  emptySelectionMessage: emptySelectionMessage,
                  allItemsSelectedMessage: allItemsSelectedMessage
                }));
}

function decodeItem(json) {
  return {
          id: Json_decode.field("id", Json_decode.string, json),
          name: Json_decode.field("name", Json_decode.string, json)
        };
}

function makeFromJson(json) {
  return SimpleMultiSelectInline({
              allItemsSelectedMessage: Json_decode.optional((function (param) {
                      return Json_decode.field("allItemsSelectedMessage", Json_decode.string, param);
                    }), json),
              emptySelectionMessage: Json_decode.optional((function (param) {
                      return Json_decode.field("emptySelectionMessage", Json_decode.string, param);
                    }), json),
              placeholder: Json_decode.optional((function (param) {
                      return Json_decode.field("placeholder", Json_decode.string, param);
                    }), json),
              selected: Json_decode.field("selected", (function (param) {
                      return Json_decode.array(decodeItem, param);
                    }), json),
              allItems: Json_decode.field("allItems", (function (param) {
                      return Json_decode.array(decodeItem, param);
                    }), json),
              inputName: Json_decode.field("inputName", Json_decode.string, json)
            });
}

var make = SimpleMultiSelectInline;

export {
  reducer ,
  SelectableItem ,
  Multiselect ,
  unselectedItems ,
  make ,
  decodeItem ,
  makeFromJson ,
}
/* Multiselect Not a pure module */
