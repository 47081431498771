if ('serviceWorker' in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker.register("/service-worker.js", {
      scope: "/"
    })
    .then(registration => {
      console.log('Service Worker registered successfully:', registration.scope);
    })
    .catch(error => {
      console.error('Service Worker registration failed:', error);
    });
  });
} else {
  console.log('Service Worker is not supported in this browser.');
}
